<template>
  <v-container fluid v-if="getPermission('setting:view')">
    <v-layout>
      <v-flex>
        <h3
          class="font-weight-900 mb-4 font-size-36 custom-headline color-custom-blue"
        >
          List Autocomplete
        </h3>
      </v-flex>
      <v-flex class="text-right">
        <v-btn
          depressed
          class="custom-grey-border custom-bold-button"
          v-on:click="goBack"
        >
          <v-icon small left>mdi-keyboard-backspace</v-icon>
          Back
        </v-btn>
      </v-flex>
    </v-layout>
    <perfect-scrollbar
      :options="{ suppressScrollX: true }"
      class="scroll custom-box-top-inner-shadow"
      style="position: relative"
    >
      <v-card flat class="custom-grey-border remove-border-radius">
        <v-card-title class="headline sticky-header grey lighten-4">
          <v-row>
            <v-col md="6" class="py-0 my-auto">
              <h3
                class="font-weight-700 custom-headline color-custom-blue"
                style="margin: 3.5px 0px"
              >
                Manage Autocomplete
              </h3>
            </v-col>
            <v-col md="6" class="text-right py-0">
              <v-btn
                depressed
                small
                v-on:click="open_note_dialog()"
                class="text-white"
                color="cyan"
                :disabled="pageLoading"
                :loading="pageLoading"
              >
                <span class="font-size-16 font-weight-600"
                  >Add Autocomplete</span
                >
              </v-btn>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text class="p-6 font-size-16">
          <v-row>
            <v-col md12>
              <table width="100%">
                <thead>
                  <tr>
                    <th align="center" class="text-center py-2" width="3%">
                      #
                    </th>
                    <th align="center" width="10%" class="text-center py-2">
                      Type
                    </th>
                    <th align="center" width="10%" class="text-center py-2">
                      Module
                    </th>
                    <th width="20%" class="py-2">Label</th>
                    <th class="py-2">Description</th>
                    <th class="py-2" width="10%"></th>
                  </tr>
                </thead>
                <tbody v-if="note_listing.length">
                  <tr
                    v-for="(row, index) in note_listing"
                    :key="index"
                    class="border-top"
                  >
                    <td align="center" class="py-2">{{ index + 1 }}</td>
                    <td align="center" class="py-2">
                      <div class="d-flex">
                        <v-chip
                          small
                          class="text-uppercase"
                          :class="`btx-autocomplete-type-${row.type}`"
                        >
                          <template v-if="row.type == 'term condition'"
                            >Term &amp; Condition
                          </template>
                          <template v-else> {{ row.type }} </template>
                        </v-chip>
                        <template v-if="row.primary">
                          <v-tooltip top content-class="custom-top-tooltip">
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                v-bind="attrs"
                                v-on="on"
                                color="green darken-1"
                                class="ml-2"
                                >mdi-record</v-icon
                              >
                            </template>
                            <span>Marked as Default</span>
                          </v-tooltip>
                        </template>
                      </div>
                    </td>
                    <td class="py-2" align="center">
                      <v-chip
                        small
                        class="text-uppercase"
                        :class="`btx-autocomplete-module-${row.module}`"
                      >
                        <template v-if="row.module == 'ticket'">Visit</template>
                        <template v-else> {{ row.module }} </template>
                      </v-chip>
                    </td>
                    <td class="py-2">{{ row.label }}</td>
                    <td class="py-2">
                      <span v-html="row.listing_desc"></span>
                    </td>
                    <td align="center" class="py-2">
                      <v-tooltip
                        left
                        content-class="custom-left-tooltip"
                        aria-expanded="true"
                        v-if="row.ticket_count > 0 || row.quotation_count > 0"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <div v-on="on" v-bind="attrs">
                            <v-btn
                              small
                              icon
                              color="cyan"
                              disabled
                              v-on:click="edit_note_dialog(row)"
                            >
                              <v-icon>mdi-pencil</v-icon>
                            </v-btn>
                            <v-btn disabled class="ml-4" small icon color="red">
                              <v-icon>mdi-delete</v-icon>
                            </v-btn>
                          </div>
                        </template>
                        <span class="font-weight-600 d-flex"
                          >This is already used in other transactions</span
                        >
                      </v-tooltip>
                      <template v-else>
                        <v-btn
                          small
                          icon
                          color="cyan"
                          v-on:click="edit_note_dialog(row)"
                        >
                          <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                        <v-btn
                          class="ml-4"
                          small
                          icon
                          color="red"
                          v-on:click="deleteDbAutocompelte(row.id)"
                        >
                          <v-icon>mdi-delete</v-icon>
                        </v-btn>
                      </template>
                    </td>
                  </tr>
                </tbody>
                <tfoot v-else>
                  <tr class="border-top border-bottom">
                    <td colspan="5" class="py-4">
                      <p
                        class="m-0 row-not-found text-center font-weight-500 font-size-16"
                      >
                        <img
                          :src="$assetURL('media/error/empty.png')"
                          class="row-not-found-image"
                        />
                        Uhh... Nothing Found.
                      </p>
                    </td>
                  </tr>
                </tfoot>
              </table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </perfect-scrollbar>
    <note-dialog :common-dialog="note_dialog" :dialog-width="dialogWidth">
      <template v-slot:title>
        <template v-if="note && note.id && note.id > 0">Update </template>
        <template v-else> Add</template> Autocomplete
      </template>
      <template v-slot:body>
        <v-container>
          <v-form
            ref="noteForm"
            v-model="noteForm"
            lazy-validation
            v-on:submit.stop.prevent="update_or_create_note()"
          >
            <table width="100%">
              <tr>
                <th valign="middle" width="15%" class="py-2">Module</th>
                <td class="py-2">
                  <v-radio-group
                    :disabled="pageLoading || formLoading"
                    v-model="note.module"
                    row
                    hide-details
                    class="mt-0"
                  >
                    <v-radio
                      color="cyan"
                      label="Visit"
                      value="ticket"
                    ></v-radio>
                    <!--   <v-radio
                      color="cyan"
                      label="Quotation"
                      value="quotation"
                    ></v-radio> -->
                    <!--  <v-radio
                      color="cyan"
                      label="Invoice"
                      value="invoice"
                    ></v-radio> -->
                    <!--  <v-radio
                      color="cyan"
                      label="Payment"
                      value="payment"
                    ></v-radio> -->
                  </v-radio-group>
                </td>
              </tr>
              <tr>
                <th valign="middle" width="15%" class="py-2">Type</th>
                <td class="py-2">
                  <v-radio-group
                    :disabled="pageLoading || formLoading"
                    v-model="note.type"
                    row
                    mandatory
                    hide-details
                    class="mt-0"
                  >
                    <v-radio
                      color="cyan"
                      label="Terms &amp; Conditions"
                      value="term_condition"
                    ></v-radio>
                    <template v-if="note.module == 'invoice'">
                      <v-radio
                        color="cyan"
                        label="Payment Terms"
                        value="payment_term"
                      ></v-radio>
                    </template>
                    <template v-if="note.module == 'invoice'">
                      <v-radio
                        color="cyan"
                        label="Internal"
                        value="internal"
                      ></v-radio>
                      <v-radio
                        color="cyan"
                        label="Client"
                        value="client"
                      ></v-radio>
                    </template>
                  </v-radio-group>
                </td>
              </tr>
              <tr>
                <th valign="middle" class="py-2">Label</th>
                <td class="py-2">
                  <v-text-field
                    placeholder="Label"
                    dense
                    solo
                    flat
                    :rules="[(v) => !!v || 'Label is required']"
                    color="cyan"
                    v-model="note.label"
                    hide-details
                    :disabled="pageLoading || formLoading"
                  ></v-text-field>
                </td>
              </tr>
              <tr>
                <th valign="top" class="pt-4">Description</th>
                <td class="py-2">
                  <tiny-mce
                    :disabled="formLoading"
                    v-model="note.description"
                  />
                </td>
              </tr>
              <tr>
                <th valign="top" class="pt-4">Default?</th>
                <td class="py-2">
                  <v-radio-group
                    hide-details
                    class="mx-0 my-0 py-0 px-0"
                    v-model="note.primary"
                    row
                  >
                    <v-radio color="cyan" label="Yes" :value="1"></v-radio>
                    <v-radio color="cyan" label="No" :value="0"></v-radio>
                  </v-radio-group>
                </td>
              </tr>
            </table>
          </v-form>
        </v-container>
      </template>
      <template v-slot:action>
        <v-btn
          depressed
          class="custom-bold-button"
          :disabled="formLoading"
          v-on:click="note_dialog = false"
          >Cancel</v-btn
        >
        <v-btn
          depressed
          class="custom-bold-button"
          :disabled="formLoading"
          :loading="formLoading"
          v-on:click="update_or_create_note()"
          color="cyan white--text"
          >Save</v-btn
        >
      </template>
    </note-dialog>
  </v-container>
</template>

<script>
import { GET, PATCH, DELETE } from "@/core/services/store/request.module";
import Dialog from "@/view/pages/partials/Dialog";
import TinyMCE from "@/view/pages/partials/TinyMCE.vue";

export default {
  name: "profile-quotation-setting",
  data() {
    return {
      pageLoading: true,
      formLoading: false,
      noteForm: true,
      note: {
        id: null,
        module: "ticket",
        type: "internal",
        label: null,
        primary: 0,
        description: null,
      },
      note_dialog: false,
      note_listing: [],
    };
  },
  methods: {
    open_note_dialog() {
      this.note = {
        id: null,
        module: "ticket",
        type: "internal",
        label: null,
        primary: 0,
        description: null,
      };
      this.$nextTick(function () {
        this.note_dialog = true;
      });
    },
    edit_note_dialog(row) {
      this.note = {
        id: row.id,
        module: row.module,
        type: row.type,
        label: row.label,
        primary: row.primary,
        description: row.description,
      };
      this.$nextTick(function () {
        this.note_dialog = true;
      });
    },
    deleteDbAutocompelte(id) {
      const _this = this;
      _this.$store
        .dispatch(DELETE, { url: `delete-autocomplete/${id}` })
        .then(() => {
          this.getQuotationNote();
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
    update_or_create_note() {
      let _this = this;

      const vStatus = _this.$refs.noteForm.validate();

      if (!vStatus) {
        return false;
      }

      _this.formLoading = true;

      setTimeout(function () {
        _this.$store
          .dispatch(PATCH, { url: "setting/autocompletes", data: _this.note })
          .then(({ data }) => {
            _this.note_listing = data;
            _this.note_dialog = false;
          })
          .catch((error) => {
            _this.logError(error);
          })
          .finally(() => {
            _this.formLoading = false;
          });
      }, 500);
    },
    getQuotationNote() {
      const _this = this;
      _this.$store
        .dispatch(GET, { url: "setting/autocompletes" })
        .then(({ data }) => {
          _this.note_listing = data;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
  },
  components: {
    "tiny-mce": TinyMCE,
    "note-dialog": Dialog,
  },
  mounted() {
    this.getQuotationNote();
  },
  computed: {
    dialogWidth() {
      return this.lodash.toSafeInteger((document.body.clientWidth / 100) * 60);
    },
  },
};
</script>
