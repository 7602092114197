<template>
  <v-container fluid class="width-80" v-if="getPermission('setting:view')">
    <v-layout>
      <v-flex>
        <h3
          class="font-weight-900 mb-4 font-size-36 custom-headline color-custom-blue"
        >
          Email Template
        </h3>
      </v-flex>
      <v-flex class="text-right">
        <v-btn
          class="custom-grey-border custom-bold-button"
          v-on:click="goBack"
        >
          <v-icon small left>mdi-keyboard-backspace</v-icon>
          Back
        </v-btn>
      </v-flex>
    </v-layout>
    <perfect-scrollbar
      :options="{ suppressScrollX: true }"
      class="scroll custom-box-top-inner-shadow"
      style="max-height: 85vh; position: relative"
    >
      <v-card
        v-if="false"
        flat
        class="custom-setting-height custom-grey-border remove-border-radius"
      >
        <v-card-title class="headline grey lighten-4">
          <v-row>
            <v-col md="4" class="py-0 my-auto">
              <h3 class="font-weight-700 custom-headline color-custom-blue">
                Quotation
              </h3>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text class="p-6 font-size-16">
          <table class="width-100">
            <tbody v-if="emailQuotationTemplates.length > 0">
              <template v-for="(template, index) in emailQuotationTemplates">
                <tr
                  :key="'title-' + index"
                  :class="{ 'custom-border-top': index > 0 }"
                >
                  <td
                    colspan="2"
                    class="pb-4 font-weight-600 font-size-16 color-custom-blue"
                    :class="{ 'py-4': index > 0 }"
                  >
                    <div class="d-flex">
                      <p class="m-0 mr-4 my-auto">
                        {{ template.template }}
                      </p>
                      <p v-if="false" class="m-0 mx-4 my-auto">
                        <v-chip
                          class="mx-2"
                          :color="
                            template.activated ? 'green' : 'red lighten-1'
                          "
                          label
                          outlined
                          small
                        >
                          <template v-if="template.activated">Active</template>
                          <template v-else>In-Active</template>
                        </v-chip>
                      </p>
                      <p class="m-0 mx-4 my-auto">
                        <v-switch
                          inset
                          color="green"
                          v-model="template.activated"
                          class="m-0"
                          :disabled="updateLoading"
                          v-on:change="
                            updateStatusEmailTemplateSetting(template, $event)
                          "
                          hide-details
                        ></v-switch>
                      </p>
                    </div>
                  </td>
                </tr>
                <tr :key="'description-' + index">
                  <td>{{ template.description_text }}</td>
                  <td class="text-right pb-4">
                    <v-btn
                      v-on:click="pushToUpdate(template.id)"
                      text
                      color="cyan"
                      class="font-size-16"
                      >Edit</v-btn
                    >
                  </td>
                </tr>
              </template>
            </tbody>
            <tfoot v-else>
              <template v-if="pageLoading">
                <tr>
                  <td colspan="2">
                    <v-skeleton-loader
                      type="text"
                      class="custom-skeleton width-200px"
                    ></v-skeleton-loader>
                  </td>
                </tr>
                <tr>
                  <td width="100%">
                    <v-skeleton-loader
                      type="text"
                      class="custom-skeleton width-300px"
                    ></v-skeleton-loader>
                  </td>
                  <td class="text-right">
                    <v-skeleton-loader
                      type="text"
                      class="custom-skeleton width-100px"
                    ></v-skeleton-loader>
                  </td>
                </tr>
              </template>
              <template v-else>
                <tr>
                  <td colspan="2">
                    <p
                      class="m-0 row-not-found text-center font-weight-500 font-size-16"
                    >
                      <img
                        :src="$assetURL('media/error/empty.png')"
                        class="row-not-found-image"
                      />
                      Uhh... There are no email template at the moment.
                    </p>
                  </td>
                </tr>
              </template>
            </tfoot>
          </table>
        </v-card-text>
      </v-card>
      <!--  <v-card flat class="custom-grey-border remove-border-radius">
        <v-card-title class="headline grey lighten-4">
          <v-row>
            <v-col md="4" class="py-0 my-auto">
              <h3 class="font-weight-700 custom-headline color-custom-blue">
                Quotation
              </h3>
            
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text class="p-6 font-size-16">
          <table class="width-100">
            <tbody v-if="emailQuotationTemplates.length > 0">
              <template v-for="(template, index) in emailQuotationTemplates">
                <tr
                  :key="'title-' + index"
                  :class="{ 'custom-border-top': index > 0 }"
                >
                  <td
                    colspan="2"
                    class="pb-4 font-weight-600 font-size-16 color-custom-blue"
                    :class="{ 'py-4': index > 0 }"
                  >
                    <div class="d-flex">
                      <p class="my-auto m-0 mr-4" style="width: 150px">
                        {{ template.template }}(All)
                      </p>
                      <p class="my-auto m-0 mx-4">
                        <v-switch
                          inset
                          color="green"
                          v-model="template.activated"
                          class="m-0"
                          :disabled="updateLoading"
                          v-on:change="
                            updateStatusEmailTemplateSetting(template, $event)
                          "
                          hide-details
                        ></v-switch>
                      </p>
                      <p class="my-auto m-0 mr-4">Admin</p>
                      <p class="my-auto m-0 mx-4">
                        <v-switch
                          inset
                          color="green"
                          v-model="template.admin"
                          class="m-0"
                          :disabled="updateLoading || !template.activated"
                          v-on:change="
                            updateAllowEmailTemplateSetting(template, 'admin')
                          "
                          hide-details
                        ></v-switch>
                      </p>
                      <p class="my-auto m-0 mr-4">Customer</p>
                      <p class="my-auto m-0 mx-4">
                        <v-switch
                          inset
                          color="green"
                          v-model="template.customer"
                          class="m-0"
                          :disabled="updateLoading || !template.activated"
                          v-on:change="
                            updateAllowEmailTemplateSetting(
                              template,
                              'customer'
                            )
                          "
                          hide-details
                        ></v-switch>
                      </p>
                      <p class="my-auto m-0 mr-4">Staff</p>
                      <p class="my-auto m-0 mx-4">
                        <v-switch
                          inset
                          color="green"
                          v-model="template.engineer"
                          class="m-0"
                          :disabled="updateLoading || !template.activated"
                          v-on:change="
                            updateAllowEmailTemplateSetting(template, 'staff')
                          "
                          hide-details
                        ></v-switch>
                      </p>
                    </div>
                  </td>
                </tr>
                <tr :key="'description-' + index">
                  <td>{{ template.description_text }}</td>
                  <td class="text-right pb-4">
                    <v-btn
                      v-on:click="pushToUpdate(template.id)"
                      text
                      color="cyan"
                      class="font-size-16"
                      >Edit</v-btn
                    >
                  </td>
                </tr>
              </template>
            </tbody>
            <tfoot v-else>
              <template v-if="pageLoading">
                <tr>
                  <td colspan="2">
                    <v-skeleton-loader
                      type="text"
                      class="custom-skeleton width-200px"
                    ></v-skeleton-loader>
                  </td>
                </tr>
                <tr>
                  <td width="100%">
                    <v-skeleton-loader
                      type="text"
                      class="custom-skeleton width-300px"
                    ></v-skeleton-loader>
                  </td>
                  <td class="text-right">
                    <v-skeleton-loader
                      type="text"
                      class="custom-skeleton width-100px"
                    ></v-skeleton-loader>
                  </td>
                </tr>
              </template>
              <template v-else>
                <tr>
                  <td colspan="2">
                    <p
                      class="m-0 row-not-found text-center font-weight-500 font-size-16"
                    >
                      <img
                        :src="$assetURL('media/error/empty.png')"
                        class="row-not-found-image"
                      />
                      Uhh... There are no email template at the moment.
                    </p>
                  </td>
                </tr>
              </template>
            </tfoot>
          </table>
        </v-card-text>
      </v-card> -->
      <v-card flat class="custom-grey-border remove-border-radius my-4">
        <v-card-title class="headline grey lighten-4">
          <v-row>
            <v-col md="4" class="py-0 my-auto">
              <h3 class="font-weight-700 custom-headline color-custom-blue">
                DOING THE JOB
              </h3>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text class="p-6 font-size-16">
          <table class="width-100">
            <tbody v-if="emailJobTemplates.length > 0">
              <template v-for="(template, index) in emailJobTemplates">
                <tr
                  :key="'title-' + index"
                  :class="{ 'custom-border-top': index > 0 }"
                >
                  <td
                    colspan="2"
                    class="pb-4 font-weight-600 font-size-16 color-custom-blue"
                    :class="{ 'py-4': index > 0 }"
                  >
                    <div class="d-flex">
                      <p class="m-0 mr-4 my-auto">
                        {{ template.template }}
                      </p>
                      <p v-if="false" class="m-0 mx-4 my-auto">
                        <v-chip
                          class="mx-2"
                          :color="
                            template.activated ? 'green' : 'red lighten-1'
                          "
                          label
                          outlined
                          small
                        >
                          <template v-if="template.activated">Active</template>
                          <template v-else>In-Active</template>
                        </v-chip>
                      </p>
                      <p class="m-0 mx-4 my-auto">
                        <v-switch
                          inset
                          color="green"
                          v-model="template.activated"
                          class="m-0"
                          :disabled="updateLoading"
                          v-on:change="
                            updateStatusEmailTemplateSetting(template, $event)
                          "
                          hide-details
                        ></v-switch>
                      </p>
                    </div>
                  </td>
                </tr>
                <tr :key="'description-' + index">
                  <td>{{ template.description_text }}</td>
                  <td class="text-right pb-4">
                    <v-btn
                      text
                      :disabled="updateLoading"
                      v-on:click="pushToUpdate(template.id)"
                      color="cyan"
                      class="font-size-16"
                      >Edit</v-btn
                    >
                  </td>
                </tr>
              </template>
            </tbody>
            <tfoot v-else>
              <template v-if="pageLoading">
                <tr>
                  <td colspan="2">
                    <v-skeleton-loader
                      type="text"
                      class="custom-skeleton width-200px"
                    ></v-skeleton-loader>
                  </td>
                </tr>
                <tr>
                  <td width="100%">
                    <v-skeleton-loader
                      type="text"
                      class="custom-skeleton width-300px"
                    ></v-skeleton-loader>
                  </td>
                  <td class="text-right">
                    <v-skeleton-loader
                      type="text"
                      class="custom-skeleton width-100px"
                    ></v-skeleton-loader>
                  </td>
                </tr>
              </template>
              <template v-else>
                <tr>
                  <td colspan="2">
                    <p
                      class="m-0 row-not-found text-center font-weight-500 font-size-16"
                    >
                      <img
                        :src="$assetURL('media/error/empty.png')"
                        class="row-not-found-image"
                      />
                      Uhh... There are no email template at the moment.
                    </p>
                  </td>
                </tr>
              </template>
            </tfoot>
          </table>
        </v-card-text>
      </v-card>
      <v-card flat class="custom-grey-border remove-border-radius my-4">
        <v-card-title class="headline grey lighten-4">
          <v-row>
            <v-col md="4" class="py-0 my-auto">
              <h3 class="font-weight-700 custom-headline color-custom-blue">
                Visit Reminder Email Notification
              </h3>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text class="p-6 font-size-16">
          <table class="width-100">
            <tbody v-if="emailJobReminderTemplates.length > 0">
              <template v-for="(template, index) in emailJobReminderTemplates">
                <tr
                  :key="'title-' + index"
                  :class="{ 'custom-border-top': index > 0 }"
                >
                  <td
                    colspan="2"
                    class="pb-4 font-weight-600 font-size-16 color-custom-blue"
                    :class="{ 'py-4': index > 0 }"
                  >
                    <div class="d-flex">
                      <p class="m-0 mr-4 my-auto">
                        {{ template.template }}
                      </p>
                      <p v-if="false" class="m-0 mx-4 my-auto">
                        <v-chip
                          class="mx-2"
                          :color="
                            template.activated ? 'green' : 'red lighten-1'
                          "
                          label
                          outlined
                          small
                        >
                          <template v-if="template.activated">Active</template>
                          <template v-else>In-Active</template>
                        </v-chip>
                      </p>
                      <p class="m-0 mx-4 my-auto">
                        <v-switch
                          inset
                          color="green"
                          v-model="template.activated"
                          class="m-0"
                          :disabled="updateLoading"
                          v-on:change="
                            updateStatusEmailTemplateSetting(template, $event)
                          "
                          hide-details
                        ></v-switch>
                      </p>
                    </div>
                  </td>
                </tr>
                <tr :key="'description-' + index">
                  <td>{{ template.description_text }}</td>
                  <td class="text-right pb-4">
                    <v-btn
                      text
                      :disabled="updateLoading"
                      v-on:click="pushToUpdate(template.id)"
                      color="cyan"
                      class="font-size-16"
                      >Edit</v-btn
                    >
                  </td>
                </tr>
              </template>
            </tbody>
            <tfoot v-else>
              <template v-if="pageLoading">
                <tr>
                  <td colspan="2">
                    <v-skeleton-loader
                      type="text"
                      class="custom-skeleton width-200px"
                    ></v-skeleton-loader>
                  </td>
                </tr>
                <tr>
                  <td width="100%">
                    <v-skeleton-loader
                      type="text"
                      class="custom-skeleton width-300px"
                    ></v-skeleton-loader>
                  </td>
                  <td class="text-right">
                    <v-skeleton-loader
                      type="text"
                      class="custom-skeleton width-100px"
                    ></v-skeleton-loader>
                  </td>
                </tr>
              </template>
              <template v-else>
                <tr>
                  <td colspan="2">
                    <p
                      class="m-0 row-not-found text-center font-weight-500 font-size-16"
                    >
                      <img
                        :src="$assetURL('media/error/empty.png')"
                        class="row-not-found-image"
                      />
                      Uhh... There are no email template at the moment.
                    </p>
                  </td>
                </tr>
              </template>
            </tfoot>
          </table>
        </v-card-text>
      </v-card>
      <v-card flat class="custom-grey-border remove-border-radius my-4">
        <v-card-title class="headline grey lighten-4">
          <v-row>
            <v-col md="4" class="py-0 my-auto">
              <h3 class="font-weight-700 custom-headline color-custom-blue">
                Service Report Email Notification
              </h3>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text class="p-6 font-size-16">
          <table class="width-100">
            <tbody v-if="emailServiceTemplates.length > 0">
              <template v-for="(template, index) in emailServiceTemplates">
                <tr
                  :key="'title-' + index"
                  :class="{ 'custom-border-top': index > 0 }"
                >
                  <td
                    colspan="2"
                    class="pb-4 font-weight-600 font-size-16 color-custom-blue"
                    :class="{ 'py-4': index > 0 }"
                  >
                    <div class="d-flex">
                      <p class="m-0 mr-4 my-auto">
                        {{ template.template }}
                      </p>
                      <p v-if="false" class="m-0 mx-4 my-auto">
                        <v-chip
                          class="mx-2"
                          :color="
                            template.activated ? 'green' : 'red lighten-1'
                          "
                          label
                          outlined
                          small
                        >
                          <template v-if="template.activated">Active</template>
                          <template v-else>In-Active</template>
                        </v-chip>
                      </p>
                      <p class="m-0 mx-4 my-auto">
                        <v-switch
                          inset
                          color="green"
                          v-model="template.activated"
                          class="m-0"
                          :disabled="updateLoading"
                          v-on:change="
                            updateStatusEmailTemplateSetting(template, $event)
                          "
                          hide-details
                        ></v-switch>
                      </p>
                    </div>
                  </td>
                </tr>
                <tr :key="'description-' + index">
                  <td>{{ template.description_text }}</td>
                  <td class="text-right pb-4">
                    <v-btn
                      text
                      :disabled="updateLoading"
                      v-on:click="pushToUpdate(template.id)"
                      color="cyan"
                      class="font-size-16"
                      >Edit</v-btn
                    >
                  </td>
                </tr>
              </template>
            </tbody>
            <tfoot v-else>
              <template v-if="pageLoading">
                <tr>
                  <td colspan="2">
                    <v-skeleton-loader
                      type="text"
                      class="custom-skeleton width-200px"
                    ></v-skeleton-loader>
                  </td>
                </tr>
                <tr>
                  <td width="100%">
                    <v-skeleton-loader
                      type="text"
                      class="custom-skeleton width-300px"
                    ></v-skeleton-loader>
                  </td>
                  <td class="text-right">
                    <v-skeleton-loader
                      type="text"
                      class="custom-skeleton width-100px"
                    ></v-skeleton-loader>
                  </td>
                </tr>
              </template>
              <template v-else>
                <tr>
                  <td colspan="2">
                    <p
                      class="m-0 row-not-found text-center font-weight-500 font-size-16"
                    >
                      <img
                        :src="$assetURL('media/error/empty.png')"
                        class="row-not-found-image"
                      />
                      Uhh... There are no email template at the moment.
                    </p>
                  </td>
                </tr>
              </template>
            </tfoot>
          </table>
        </v-card-text>
      </v-card>
      <v-card flat class="custom-grey-border remove-border-radius mt-4">
        <v-card-title class="headline grey lighten-4">
          <v-row>
            <v-col md="4" class="py-0 my-auto">
              <h3
                class="font-weight-700 custom-headline color-custom-blue"
                style="margin: 3.5px 0px"
              >
                Service Report Email (for GAIA)
              </h3>
            </v-col>
            <template v-if="getPermission('setting:update')">
              <v-col md="8" class="text-right py-0">
                <v-btn
                  small
                  class="text-white"
                  color="cyan"
                  v-on:click="updateServieEmailSetting"
                  :disabled="emailLoading || !formValid"
                  :loading="emailLoading"
                >
                  <span class="font-size-16 font-weight-600">Update</span>
                </v-btn>
              </v-col>
            </template>
          </v-row>
        </v-card-title>
        <v-card-text
          class="p-6 font-size-16"
          :class="{ 'pointer-events-none': !getPermission('setting:update') }"
        >
          <v-row>
            <v-col md="12" class="pb-4">
              <v-form
                ref="customerForm"
                v-model.trim="formValid"
                lazy-validation
                v-on:submit.stop.prevent="updateServieEmailSetting"
              >
                <table class="width-100 mt-4">
                  <tbody>
                    <tr>
                      <!--Service Form Allowed in Visit-->
                      <v-text-field
                        v-model.trim="service_report_email"
                        :rules="[
                          // validateRules.required(service_report_email, 'email'),
                          validateRules.validEmail(
                            service_report_email,
                            'email'
                          ),
                          validateRules.minLength(
                            service_report_email,
                            'email',
                            2
                          ),
                          validateRules.maxLength(
                            service_report_email,
                            'email',
                            100
                          ),
                        ]"
                        dense
                        filled
                        placeholder="Email"
                        solo
                        flat
                        color="cyan"
                      ></v-text-field>
                    </tr>
                  </tbody>
                </table>
              </v-form>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <!--  <v-card flat class="custom-grey-border remove-border-radius my-4">
        <v-card-title class="headline grey lighten-4">
          <v-row>
            <v-col md="4" class="py-0 my-auto">
              <h3 class="font-weight-700 custom-headline color-custom-blue">
                GETTING PAID
              </h3>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text class="p-6 font-size-16">
          <table class="width-100">
            <tbody v-if="emailPaymentTemplates.length > 0">
              <template v-for="(template, index) in emailPaymentTemplates">
                <tr
                  :key="'title-' + index"
                  :class="{ 'custom-border-top': index > 0 }"
                >
                  <td
                    colspan="2"
                    class="pb-4 font-weight-600 font-size-16 color-custom-blue"
                    :class="{ 'py-4': index > 0 }"
                  >
                    <div class="d-flex">
                      <p class="m-0 mr-4 my-auto">
                        {{ template.template }}
                      </p>
                      <p v-if="false" class="m-0 mx-4 my-auto">
                        <v-chip
                          class="mx-2"
                          :color="
                            template.activated ? 'green' : 'red lighten-1'
                          "
                          label
                          outlined
                          small
                        >
                          <template v-if="template.activated">Active</template>
                          <template v-else>In-Active</template>
                        </v-chip>
                      </p>
                      <p class="m-0 mx-4 my-auto">
                        <v-switch
                          inset
                          color="green"
                          v-model="template.activated"
                          class="m-0"
                          :disabled="updateLoading"
                          v-on:change="
                            updateStatusEmailTemplateSetting(template, $event)
                          "
                          hide-details
                        ></v-switch>
                      </p>
                    </div>
                  </td>
                </tr>
                <tr :key="'description-' + index">
                  <td>{{ template.description_text }}</td>
                  <td class="text-right pb-4">
                    <v-btn
                      text
                      :disabled="updateLoading"
                      v-on:click="pushToUpdate(template.id)"
                      color="cyan"
                      class="font-size-16"
                      >Edit</v-btn
                    >
                  </td>
                </tr>
              </template>
            </tbody>
            <tfoot v-else>
              <template v-if="pageLoading">
                <tr>
                  <td colspan="2">
                    <v-skeleton-loader
                      type="text"
                      class="custom-skeleton width-200px"
                    ></v-skeleton-loader>
                  </td>
                </tr>
                <tr>
                  <td width="100%">
                    <v-skeleton-loader
                      type="text"
                      class="custom-skeleton width-300px"
                    ></v-skeleton-loader>
                  </td>
                  <td class="text-right">
                    <v-skeleton-loader
                      type="text"
                      class="custom-skeleton width-100px"
                    ></v-skeleton-loader>
                  </td>
                </tr>
              </template>
              <template v-else>
                <tr>
                  <td colspan="2">
                    <p
                      class="m-0 row-not-found text-center font-weight-500 font-size-16"
                    >
                      <img
                        :src="$assetURL('media/error/empty.png')"
                        class="row-not-found-image"
                      />
                      Uhh... There are no email template at the moment.
                    </p>
                  </td>
                </tr>
              </template>
            </tfoot>
          </table>
        </v-card-text>
      </v-card> -->
      <v-card flat class="custom-grey-border remove-border-radius my-4">
        <v-card-title class="headline grey lighten-4">
          <v-row>
            <v-col md="4" class="py-0 my-auto">
              <h3 class="font-weight-700 custom-headline color-custom-blue">
                USER NOTIFICATIONS
              </h3>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text class="p-6 font-size-16">
          <table class="width-100">
            <tbody v-if="emailUserTemplates.length > 0">
              <template v-for="(template, index) in emailUserTemplates">
                <tr
                  :key="'title-' + index"
                  :class="{ 'custom-border-top': index > 0 }"
                >
                  <td
                    colspan="2"
                    class="pb-4 font-weight-600 font-size-16 color-custom-blue"
                    :class="{ 'py-4': index > 0 }"
                  >
                    <div class="d-flex">
                      <p class="m-0 mr-4 my-auto">
                        {{ template.template }}
                      </p>
                      <p v-if="false" class="m-0 mx-4 my-auto">
                        <v-chip
                          class="mx-2"
                          :color="
                            template.activated ? 'green' : 'red lighten-1'
                          "
                          label
                          outlined
                          small
                        >
                          <template v-if="template.activated">Active</template>
                          <template v-else>In-Active</template>
                        </v-chip>
                      </p>
                      <p class="m-0 mx-4 my-auto">
                        <v-switch
                          inset
                          color="green"
                          v-model="template.activated"
                          class="m-0"
                          :disabled="updateLoading"
                          v-on:change="
                            updateStatusEmailTemplateSetting(template, $event)
                          "
                          hide-details
                        ></v-switch>
                      </p>
                    </div>
                  </td>
                </tr>
                <tr :key="'description-' + index">
                  <td>{{ template.description_text }}</td>
                  <td class="text-right pb-4">
                    <v-btn
                      text
                      :disabled="updateLoading"
                      v-on:click="pushToUpdate(template.id)"
                      color="cyan"
                      class="font-size-16"
                      >Edit</v-btn
                    >
                  </td>
                </tr>
              </template>
            </tbody>
            <tfoot v-else>
              <template v-if="pageLoading">
                <tr>
                  <td colspan="2">
                    <v-skeleton-loader
                      type="text"
                      class="custom-skeleton width-200px"
                    ></v-skeleton-loader>
                  </td>
                </tr>
                <tr>
                  <td width="100%">
                    <v-skeleton-loader
                      type="text"
                      class="custom-skeleton width-300px"
                    ></v-skeleton-loader>
                  </td>
                  <td class="text-right">
                    <v-skeleton-loader
                      type="text"
                      class="custom-skeleton width-100px"
                    ></v-skeleton-loader>
                  </td>
                </tr>
              </template>
              <template v-else>
                <tr>
                  <td colspan="2">
                    <p
                      class="m-0 row-not-found text-center font-weight-500 font-size-16"
                    >
                      <img
                        :src="$assetURL('media/error/empty.png')"
                        class="row-not-found-image"
                      />
                      Uhh... There are no email template at the moment.
                    </p>
                  </td>
                </tr>
              </template>
            </tfoot>
          </table>
        </v-card-text>
      </v-card>
      <v-card
        v-if="false"
        flat
        class="custom-grey-border remove-border-radius my-4"
      >
        <v-card-title class="headline grey lighten-4">
          <v-row>
            <v-col md="12" class="py-0 my-auto">
              <h3 class="font-weight-700 custom-headline color-custom-blue">
                CUSTOMER NOTIFICATIONS
              </h3>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text class="p-6 font-size-16">
          <table class="width-100">
            <tbody v-if="emailCustomerTemplates.length > 0">
              <template v-for="(template, index) in emailCustomerTemplates">
                <tr
                  :key="'title-' + index"
                  :class="{ 'custom-border-top': index > 0 }"
                >
                  <td
                    colspan="2"
                    class="pb-4 font-weight-600 font-size-16 color-custom-blue"
                    :class="{ 'py-4': index > 0 }"
                  >
                    <div class="d-flex">
                      <p class="m-0 mr-4 my-auto">
                        {{ template.template }}
                      </p>
                      <p v-if="false" class="m-0 mx-4 my-auto">
                        <v-chip
                          class="mx-2"
                          :color="
                            template.activated ? 'green' : 'red lighten-1'
                          "
                          label
                          outlined
                          small
                        >
                          <template v-if="template.activated">Active</template>
                          <template v-else>In-Active</template>
                        </v-chip>
                      </p>
                      <p class="m-0 mx-4 my-auto">
                        <v-switch
                          inset
                          color="green"
                          v-model="template.activated"
                          class="m-0"
                          :disabled="updateLoading"
                          v-on:change="
                            updateStatusEmailTemplateSetting(template, $event)
                          "
                          hide-details
                        ></v-switch>
                      </p>
                    </div>
                  </td>
                </tr>
                <tr :key="'description-' + index">
                  <td>{{ template.description_text }}</td>
                  <td class="text-right pb-4">
                    <v-btn
                      text
                      :disabled="updateLoading"
                      v-on:click="pushToUpdate(template.id)"
                      color="cyan"
                      class="font-size-16"
                      >Edit</v-btn
                    >
                  </td>
                </tr>
              </template>
            </tbody>
            <tfoot v-else>
              <template v-if="pageLoading">
                <tr>
                  <td colspan="2">
                    <v-skeleton-loader
                      type="text"
                      class="custom-skeleton width-200px"
                    ></v-skeleton-loader>
                  </td>
                </tr>
                <tr>
                  <td width="100%">
                    <v-skeleton-loader
                      type="text"
                      class="custom-skeleton width-300px"
                    ></v-skeleton-loader>
                  </td>
                  <td class="text-right">
                    <v-skeleton-loader
                      type="text"
                      class="custom-skeleton width-100px"
                    ></v-skeleton-loader>
                  </td>
                </tr>
              </template>
              <template v-else>
                <tr>
                  <td colspan="2">
                    <p
                      class="m-0 row-not-found text-center font-weight-500 font-size-16"
                    >
                      <img
                        :src="$assetURL('media/error/empty.png')"
                        class="row-not-found-image"
                      />
                      Uhh... There are no email template at the moment.
                    </p>
                  </td>
                </tr>
              </template>
            </tfoot>
          </table>
        </v-card-text>
      </v-card>
      <v-card v-if="false" flat class="custom-grey-border remove-border-radius">
        <v-card-title class="headline grey lighten-4">
          <v-row>
            <v-col md="4" class="py-0 my-auto">
              <h3 class="font-weight-700 custom-headline color-custom-blue">
                TASK NOTIFICATIONS
              </h3>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text class="p-6 font-size-16">
          <table class="width-100">
            <tbody v-if="emailTaskTemplates.length > 0">
              <template v-for="(template, index) in emailTaskTemplates">
                <tr
                  :key="'title-' + index"
                  :class="{ 'custom-border-top': index > 0 }"
                >
                  <td
                    colspan="2"
                    class="pb-4 font-weight-600 font-size-16 color-custom-blue"
                    :class="{ 'py-4': index > 0 }"
                  >
                    <div class="d-flex">
                      <p class="m-0 mr-4 my-auto">
                        {{ template.template }}
                      </p>
                      <p v-if="false" class="m-0 mx-4 my-auto">
                        <v-chip
                          class="mx-2"
                          :color="
                            template.activated ? 'green' : 'red lighten-1'
                          "
                          label
                          outlined
                          small
                        >
                          <template v-if="template.activated">Active</template>
                          <template v-else>In-Active</template>
                        </v-chip>
                      </p>
                      <p class="m-0 mx-4 my-auto">
                        <v-switch
                          inset
                          color="green"
                          v-model="template.activated"
                          class="m-0"
                          :disabled="updateLoading"
                          v-on:change="
                            updateStatusEmailTemplateSetting(template, $event)
                          "
                          hide-details
                        ></v-switch>
                      </p>
                    </div>
                  </td>
                </tr>
                <tr :key="'description-' + index">
                  <td>{{ template.description_text }}</td>
                  <td class="text-right pb-4">
                    <v-btn
                      text
                      :disabled="updateLoading"
                      v-on:click="pushToUpdate(template.id)"
                      color="cyan"
                      class="font-size-16"
                      >Edit</v-btn
                    >
                  </td>
                </tr>
              </template>
            </tbody>
            <tfoot v-else>
              <template v-if="pageLoading">
                <tr>
                  <td colspan="2">
                    <v-skeleton-loader
                      type="text"
                      class="custom-skeleton width-200px"
                    ></v-skeleton-loader>
                  </td>
                </tr>
                <tr>
                  <td width="100%">
                    <v-skeleton-loader
                      type="text"
                      class="custom-skeleton width-300px"
                    ></v-skeleton-loader>
                  </td>
                  <td class="text-right">
                    <v-skeleton-loader
                      type="text"
                      class="custom-skeleton width-100px"
                    ></v-skeleton-loader>
                  </td>
                </tr>
              </template>
              <template v-else>
                <tr>
                  <td colspan="2">
                    <p
                      class="m-0 row-not-found text-center font-weight-500 font-size-16"
                    >
                      <img
                        :src="$assetURL('media/error/empty.png')"
                        class="row-not-found-image"
                      />
                      Uhh... There are no email template at the moment.
                    </p>
                  </td>
                </tr>
              </template>
            </tfoot>
          </table>
        </v-card-text>
      </v-card>
    </perfect-scrollbar>
  </v-container>
</template>

<script>
import { GET, PATCH } from "@/core/services/store/request.module";
import ValidationMixin from "@/core/plugins/validation-mixin";

export default {
  name: "profile-service-setting",
  mixins: [ValidationMixin],
  data() {
    return {
      pageLoading: true,
      templateLoading: true,
      template: 0,
      emailJobReminderTemplates: new Array(),
      emailQuotationTemplates: new Array(),
      emailJobTemplates: new Array(),
      emailPaymentTemplates: new Array(),
      emailServiceTemplates: new Array(),
      emailUserTemplates: new Array(),
      emailCustomerTemplates: new Array(),
      emailTaskTemplates: new Array(),
      updateLoading: false,
      emailLoading: false,
      service_report_email: null,
    };
  },
  methods: {
    updateServieEmailSetting() {
      const _this = this;
      _this.emailLoading = true;
      _this.$store
        .dispatch(PATCH, {
          url: "setting/service-email",
          data: {
            service_report_email: this.service_report_email,
          },
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.getJobSetting();
          _this.emailLoading = false;
        });
    },
    getJobSetting() {
      const _this = this;
      _this.emailLoading = true;
      _this.$store
        .dispatch(GET, { url: "setting/job" })
        .then(({ data }) => {
          _this.service_report_email = data.service_report_email
            ? data.service_report_email
            : null;
        })
        .catch((error) => {
          _this.logError(error);
          _this.emailLoading = false;
        })
        .finally(() => {
          _this.emailLoading = false;
        });
    },
    pushToUpdate(id) {
      this.$router.push(
        this.getDefaultRoute("email.template.update", {
          params: { id },
        })
      );
    },
    getEmailTemplateSetting() {
      const _this = this;
      _this.$store
        .dispatch(GET, { url: "setting/email-template" })
        .then(({ data }) => {
          _this.emailQuotationTemplates = _this.lodash.filter(
            data,
            function (row) {
              return row.group == "quotation";
            }
          );
          let modulesToInclude = [
            "visit-remainder-1-day--before",
            "visit-reminder-1-week--before",
            "visit-reminder-15-day-before",
            "visit-reminder--before-1-month",
            "visit-service-day",
          ];
          _this.emailJobReminderTemplates = data.filter((template) => {
            return (
              template.group == "job" &&
              modulesToInclude.includes(template.module)
            );
          });
          _this.emailJobTemplates = _this.lodash.filter(data, function (row) {
            return row.group == "job" && !modulesToInclude.includes(row.module);
          });
          _this.emailPaymentTemplates = _this.lodash.filter(
            data,
            function (row) {
              return row.group == "payment";
            }
          );
          _this.emailServiceTemplates = _this.lodash.filter(
            data,
            function (row) {
              return row.group == "servicereport";
            }
          );
          _this.emailUserTemplates = _this.lodash.filter(data, function (row) {
            return row.group == "user";
          });
          _this.emailCustomerTemplates = _this.lodash.filter(
            data,
            function (row) {
              return row.group == "customer";
            }
          );
          _this.emailTaskTemplates = _this.lodash.filter(data, function (row) {
            return row.group == "task";
          });
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
    updateStatusEmailTemplateSetting(template, status) {
      const _this = this;
      _this.updateLoading = true;
      _this.$store
        .dispatch(PATCH, {
          url: `setting/email-template/${template.id}/status/${+!!status}`,
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.updateLoading = false;
        });
    },
    updateAllowEmailTemplateSetting(template, type) {
      const _this = this;
      _this.updateLoading = true;
      _this.$store
        .dispatch(PATCH, {
          url: `setting/email-template/${type}`,
          data: {
            id: template.id,
            activated: template.activated,
            admin: template.admin,
            customer: template.customer,
            engineer: template.engineer,
            module: template.module,
            category: template.category,
          },
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.updateLoading = false;
        });
    },
  },
  mounted() {
    this.getEmailTemplateSetting();
    this.getJobSetting();
  },
};
</script>
